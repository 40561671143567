import React, { Component, Fragment } from 'react'
// import trashRedPng from '../../common/images/icons/trash-red.png';
import { fetchResturantTax, fetchRestaurantCardProcessingFee, fetchRestaurantProvince, fetchResturantDeliveryAvailability,fetchResturantDeliveryFees,fetchResturantPhoneNumber } from '../../../actions/settings_actions';
import { removeCartItem,removeCartSubItem  } from '../../../actions/cart_actions';
// import {S3_URL} from '../../utils/misc';
// import {getParameterByName,getUniqueNameWithTimeStamp,showMsg } from '../../utils/general';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../../utils/LoadingSpinner';
import OrderReviewCartItemBlock from './components/OrderReviewCartItemBlock';
import dropLeftPng from '../../common/images/icons/drop-left.png';
import { fetchMaxTicketNumberOfOrder,submitCreateOrder  } from '../../../actions/order_actions';
import { fetchResturantStripeApiKeys  } from '../../../actions/settings_actions';
import {showMsg,formatOrderDate,addTimeToCurrentDateTime} from '../../utils/general';
import { socket } from '../../layout/Header';
import { clearCart  } from '../../../actions/cart_actions';
// import Script from 'react-load-script';
// import oldLogoPng from '../../common/images/mini-logo.png'
import { validateDeliveryAmount } from '../../utils/AddressDetailPage/Validations';
import { countOrderItems, calculateTotalHelper, calculateTotalIncludingFee, calculateFinalTotalOfOrder } from '../../utils/OrdersPages/OrdersHelpers';
import PickupForm from './components/PickupForm/PickupForm';
import moment from 'moment';
// import SubmitOrderButtons from './components/SubmitOrderButtons';
class OrderReview extends Component {
    _isMounted = false;
    state = {
        tax : 0,
        taxAmount:0,
        subTotal:0,
        total:0,
        // totalAmount:0,
        deliveryAvailability:'loading',
        cartDetail:[],
        selectedDeliveryType:'',
        selectedAuthType:'',
        showAuthModal:false,
        deliveryFees:{},
        values:{},
        maxTicketNumber:'',
        isOrderSavingLoader:false,
        redirect_to:false,
        stripeToken:false,
        restaurant_phone_number:'',
        restaurant_stripe_publishable_key:'',
        is_stripe_keys_setup:false,
        loading_stripe_keys:false,
        restaurant_province:'',
        restaurant_province_id:'',
        card_processing_fee:false,
        selectedDeliveryPaymentMethod:'',
        timeSelectionRadioBox:'have_ready_in',
        time_picker_time:'',
        drp_default_time:15,
        // loading_stripe_keys:true,
    }
    
    // There is only one reason when one needs to pass props to super():

    // When you want to access this.props in constructor.
    constructor(props) {
        super(props);
        // this.handleStripeScriptLoad = this.handleStripeScriptLoad.bind(this);
        this.btnCredit = React.createRef();
        this.btnCash = React.createRef();
        this.btnContinue = React.createRef();
        this.pickupForm = React.createRef();
    }
    componentDidMount() {
        this._isMounted = true;
        
        const orderSubmitFormValues = sessionStorage.getItem('orderSubmitFormValues');
        this.setState({values:orderSubmitFormValues});

        let cart = this.props.cart;
        if(cart.length === 0){
            showMsg("Please add some items to the cart.",'info');
            this.props.history.push('/');
            return false;
        }
        
        // let isRestaurantIsOpen = this.props.isRestaurantIsOpen.is_restaurant_open;
        if(!this.props.isRestaurantOpen){
            // showMsg("Restaurant is currently closed. Please check back during business hours.",'info');
            this.props.history.push('/');
            return false;
        }
        // this.props.fetchResturantTax().then(response => {
        //     this.setState({tax:this.props.objtax.tax});
        //     this.calculateTotal(cart);
        // });
        this.calculateTotal(cart);
        this.props.fetchRestaurantProvince().then(response => {
            let restaurant_province = this.props.objRestaurantProvince.restaurant_province;
            let restaurant_province_id = this.props.objRestaurantProvince.restaurant_province_id;
            let tax = 13;
            // if(restaurant_province.toLowerCase() === 'ontario'){
            //     // alert('dssdds')
            //     if(parseFloat(this.state.subTotal) <= 4){
            //         tax = 5; 
            //     }else if(parseFloat(this.state.subTotal) > 4){
            //         tax = 13; 
            //     }
            // }
            this.setState({ tax,restaurant_province,restaurant_province_id},()=>{
                this.calculateTotal(cart);
            });
        });
        this.props.fetchRestaurantCardProcessingFee().then(response => {
            this.setState({card_processing_fee:this.props.objRestaurantCardProcessingFee.card_processing_fee});
        });
        this.props.fetchResturantDeliveryAvailability().then(response => {
            this.setState({deliveryAvailability:this.props.objResturantDeliveryAvailability.is_delivery_available});
        });
        this.props.fetchResturantDeliveryFees().then(response => {
            // console.log('this.props.objRestaurantDeliveryFees in resp');
            // console.log('this.props.objRestaurantDeliveryFees in resp');
            // console.log('this.props.objRestaurantDeliveryFees in resp');
            console.log(this.props.objRestaurantDeliveryFees);
            this.setState({deliveryFees:this.props.objRestaurantDeliveryFees.delivery_fees});
            // console.log(this.props.objtax);
        });
        this.props.fetchResturantPhoneNumber().then(response => {
            // console.log('this.props.objRestaurantPhoneNumber in resp');
            // console.log('this.props.objRestaurantPhoneNumber in resp');
            // console.log('this.props.objRestaurantPhoneNumber in resp');
            console.log(this.props.objRestaurantPhoneNumber);
            // alert(this.props.objRestaurantPhoneNumber.restaurant_phone_number);
            this.setState({restaurant_phone_number:this.props.objRestaurantPhoneNumber.restaurant_phone_number});
            // console.log(this.props.objtax);
        });
        let delivery_type = this.props.match.params.delivery_type;
        this.setState({delivery_type});
        this.props.fetchMaxTicketNumberOfOrder().then(response => {
            if (this._isMounted) {
                this.setState({maxTicketNumber:this.props.order.maxTicketNumber});
            }
        });
        // this.props.fetchResturantStripeApiKeys().then(response => {
        //     console.log('this.props.objRestaurantStripeApiKeys')
        //     console.log('this.props.objRestaurantStripeApiKeys')
        //     console.log('this.props.objRestaurantStripeApiKeys')
        //     console.log(this.props.objRestaurantStripeApiKeys)
        //     this.setState({loading_stripe_keys:false});
        //     if(this.props.objRestaurantStripeApiKeys.restaurant_stripe_publishable_key === null){
        //         this.setState({is_stripe_keys_setup:false});
        //     }else{
        //         this.setState({is_stripe_keys_setup:true});
        //     }
        //     this.setState({restaurant_stripe_publishable_key:this.props.objRestaurantStripeApiKeys.restaurant_stripe_publishable_key});
        // });
        window.OrderReview = this;
        // console.log('this.props.objtax');
        // console.log(this.props.objtax);

    }
    componentWillUnmount() {
        this._isMounted = false;
        if(this.stripeHandler) {
            this.stripeHandler.close();
        }
    }
    calculateTotal = (cartDetailData) => {
        let {total,cartDetail} = calculateTotalHelper(cartDetailData);
        this.setState({
            subTotal:total,
            cartDetail
        });
    }

    handelOnChangeTimeKeeper = (value) => {
        console.log('value in handelOnChangeTimeKeeper')
        console.log(value)
        // console.log(moment())
        let date = new Date();
        date = moment(date);
        // date = moment(date).minutes(value.hour).hours(value.minute);
        date.set({hour:value.hour,minute:value.minute})
        this.setState({time_picker_time:date})
    }
    handleOnChangetimeSelectionRadioBox = (timeSelectionRadioBox) =>{
        this.setState({timeSelectionRadioBox}); 
    }
    handleOnChangeDrfDefaultTime = (drp_default_time) =>{
        this.setState({drp_default_time}); 
    }
    
    btnOnClickPaymentMethodType = async (payment_method_type) => {
        if(payment_method_type  === 'credit'){
            this.setState({selectedDeliveryPaymentMethod:payment_method_type},()=>{
                this.calculateTotal(this.props.cart);
            });
        }else{
            this.setState({selectedDeliveryPaymentMethod:payment_method_type});
        }
    }
    onSubmitPickupForm = async (values) => {
        let error = false;
        console.log('values in onSubmitPickupForm')
        console.log(values)
        if(values.timeSelectionRadioBox === "have_ready_in" && (!values.drp_default_time || values.drp_default_time === "")){
            showMsg("Please select time for the order.",'error');
            error = true;
        }else if(values.timeSelectionRadioBox === "other_time"){
            if(this.state.time_picker_time === ''){
                showMsg("Please select time.",'error');
                error = true;
            }else{
                const selectedDate = new Date(this.state.time_picker_time);
                const todayDateTime = new Date();
                if(selectedDate > todayDateTime){
                }else{
                    showMsg('Please select time greater then current time','error');
                    error = true;
                }
            }
        }
        if(error === false){
            this.btnContinue.current.disabled = true;
            this.onSubmitForm('cash');
        }
        return values;
    }
    btnOnConfirmClick = async () => {
        // console.log('this.state.card_processing_fee')
        // console.log(this.state.card_processing_fee)
        let objValues = JSON.parse(sessionStorage.getItem('orderSubmitFormValues'));
        if(this.state.card_processing_fee === false  || objValues.delivery_type === 'pickup'){
            // alert(this.state.card_processing_fee)
            // alert(objValues.delivery_type)
            if(objValues.delivery_type === 'pickup'){
                let submitFormData = await this.pickupForm.current.submitForm();
                // console.log('submitFormData')
                // console.log('submitFormData')
                // console.log(submitFormData)
                return false;

            }else if(objValues.delivery_type === 'delivery') {
                this.btnContinue.current.disabled = true;
                this.onSubmitForm('cash');
            }
        }else{
            if(this.state.selectedDeliveryPaymentMethod === ''){
                showMsg('Please select how will you pay the delivery driver when they arrive?','error');
                return false;
            }
            
            this.btnContinue.current.disabled = true;            
            this.onSubmitForm(this.state.selectedDeliveryPaymentMethod)
        }
    }
    onSubmitForm = async (payment_type) => {
        // if(payment_type === 'credit'){
        //     this.btnCredit.current.disabled = true;
        // }
        // if(payment_type === 'cash'){
        //     this.btnCash.current.disabled = true;
        // }
        let objValues = JSON.parse(sessionStorage.getItem('orderSubmitFormValues'));
        // alert(objValues.delivery_type)
        // alert(objValues.delivery_amount)
        if(objValues.delivery_type === 'delivery'){
            let objIsValidDeliveryAmount = validateDeliveryAmount(objValues.delivery_amount,this.props.objRestaurantPhoneNumber)
            if(objIsValidDeliveryAmount.error){
                showMsg(objIsValidDeliveryAmount.msg,'error');
                this.setState({isOrderSavingLoader:false});
                return false;
            }
        }
        // console.log('sddsds')
        // return false;
        if(payment_type === 'cash'){
            this.storeOrder(payment_type);
        }else if(payment_type === 'credit'){
            // let values = JSON.parse(sessionStorage.getItem('orderSubmitFormValues'));
            this.setState({isOrderSavingLoader:true});
            // this.stripeHandler.open({
            //     name: 'Jubzi',
            //     description: 'Complete your order',
            //     // panelLabel: 'Pay Now',
            //     allowRememberMe: true,
            //     currency: 'CAD',
            //     //USD,EUR,AUD,GBP
            //     amount: values.total_amount_after_tax_delivery_charges* 100
            // });
            this.storeOrder('credit');
        }
         
        
        
        
    }
    storeOrder = async (payment_type,token = null) => {
        // alert(this.state.drp_default_time)
        this.setState({isOrderSavingLoader:true});
        let values = JSON.parse(sessionStorage.getItem('orderSubmitFormValues'));
        // if(payment_type === 'credit'){
        //     values.stripe_token = token.id;
        //     values.payment_method = 'credit_card'; 
        // }
        values.payment_method = payment_type; 
        values.province_name = this.state.restaurant_province;
        values.taxPer = this.state.tax;
        values.province_id = this.state.restaurant_province_id;
        if(values.delivery_type === 'pickup'){
            // console.log('this.state.timeSelectionRadioBox')
            // console.log('this.state.timeSelectionRadioBox')
            // console.log(this.state.timeSelectionRadioBox)
            // const now = moment().hour(12).minute(0);
            values.timeSelectionRadioBox = this.state.timeSelectionRadioBox;
            values.objCustomer.timeSelectionRadioBox = this.state.timeSelectionRadioBox;
            if(this.state.timeSelectionRadioBox === "have_ready_in"){
                let time_picker_time = addTimeToCurrentDateTime(this.state.drp_default_time)
                values.drp_default_time = this.state.drp_default_time;
                values.order_completion_datetime = time_picker_time;
                values.time_picker_time = time_picker_time;
                values.objCustomer.time_picker_time = time_picker_time;
                values.objCustomer.drp_default_time = this.state.drp_default_time;
            }else if(this.state.timeSelectionRadioBox === "other_time"){
                let time_picker_time = new Date(this.state.time_picker_time);
                values.order_completion_datetime = time_picker_time;
                values.time_picker_time = time_picker_time;
                values.objCustomer.time_picker_time = time_picker_time;
                // values.order_completion_datetime = new Date(this.state.time_picker_time);
            }
            
        }else{
            // console.log("I am in the else")
            values.drp_default_time = 15;
            let time_picker_time = addTimeToCurrentDateTime(values.drp_default_time)
            values.time_picker_time = time_picker_time;
            values.objCustomer.time_picker_time = time_picker_time;
            values.objCustomer.drp_default_time = values.drp_default_time;
            
            values.order_completion_datetime = time_picker_time;
        }
        // console.log('values.drp_default_time')
        // console.log('values.drp_default_time')
        // console.log(values.drp_default_time)
        values.currentDatetime = new Date();
        values.payment_type = payment_type;
        
        values.delivery_payment_method = this.state.selectedDeliveryPaymentMethod;
        if(values.delivery_type === 'delivery' && this.state.selectedDeliveryPaymentMethod === 'credit'){
            values.card_processing_fee = this.state.card_processing_fee;
        }
        values.total_including_fee = calculateTotalIncludingFee(values.total,values.delivery_amount,this.state.card_processing_fee,values.delivery_type,this.state.selectedDeliveryPaymentMethod);
        values.final_total = calculateFinalTotalOfOrder(values,this.state.selectedDeliveryPaymentMethod,this.state.card_processing_fee);
        if(values.cart.length > 0){
            values.cart.forEach((item,index)=>{
                // To make the size of order less
                values.cart[index].MenuItemsIngredientsMultiple = [];
            });
        }
        console.log('values')
        console.log('values')
        console.log(values)
        // console.log('values.drp_default_time after')
        // console.log('values.drp_default_time after')
        // console.log(values.drp_default_time)
        this.props.submitCreateOrder(values).then(response => {
            // console.log('this.props.order');
            // console.log(this.props.order);
            // if(orderSavedSuccess)
            this.setState({isOrderSavingLoader:false});
            let orderResponse = this.props.order.orderSavedSuccess;
            if(orderResponse.error){
                this.btnContinue.current.disabled = false;
                showMsg(orderResponse.msg,'error');
            }else{
                // alert('success')
                // socket.emit("putOrder", orderResponse.objOrder);
                const push_notification_subscription_token = localStorage.getItem("push_notification_subscription_token");
                socket.emit("putOrder", {objOrder:orderResponse.objOrder,push_notification_subscription_token});
                console.log('{objOrder:orderResponse.objOrder,push_notification_subscription_token}')
                console.log('{objOrder:orderResponse.objOrder,push_notification_subscription_token}')
                console.log('{objOrder:orderResponse.objOrder,push_notification_subscription_token}')
                console.log({objOrder:orderResponse.objOrder,push_notification_subscription_token})
                // console.log('orderResponse.objOrder')
                // console.log(orderResponse.objOrder)
                if(payment_type === 'cash'){
                    showMsg("Please proceed to cashier to pay for your order.",'success');
                }else{
                    showMsg(orderResponse.msg,'success');
                }
                // console.log('i have putOrder');
                // console.log('orderResponse.order_id')
                // console.log(orderResponse.order_id)
                this.props.clearCart();
                // alert('sss')
                sessionStorage.removeItem('orderSubmitFormValues');
                sessionStorage.removeItem('homePageCustomerFormValues');
                this.props.history.push(`/order-details/${orderResponse.order_id}?redirect_to=home`);
            }
        });
        
    }


    /* handleStripeScriptLoad(){
        // alert(this.state.restaurant_stripe_publishable_key)
        this.stripeHandler = window.StripeCheckout.configure({
            key: this.state.restaurant_stripe_publishable_key,
            // key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
            // key: 'pk_test_UaA35cqi5DTDnyyihMyh9DZE00BFSogLSQ',process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
            image: oldLogoPng,
            // image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
            
            locale: 'auto',
            
            // token: function(token) {
            token: (token) => {
                this.setState({isOrderSavingLoader:false,stripeToken:token});
                // this.setState({isOrderSavingLoader:true});
                // this.submitOrderEnd(token)
                console.log('token in submit')
                console.log(token)
                // values.stripe_token = token.id; 
                this.storeOrder('credit',token)               
              // You can access the token ID with `token.id`.
              // Get the token ID to your server-side code for use.
            },
            closed: () => {
                // when the stripe opener is closed
                if(this.state.stripeToken === false){
                    this.setState({isOrderSavingLoader:false});
                }
            }
        });
    } */
        
    
    
    render() {
        // const objtax = this.props.objtax;
        const objRestaurantProvince = this.props.objRestaurantProvince;
        // const cart = this.props.cart;
        const cart = this.state.cartDetail;
        // console.log('this.state.values')
        // console.log('this.state.values')
        let values = JSON.parse(sessionStorage.getItem('orderSubmitFormValues')); 
        let initialValues = {
            timeSelectionRadioBox: this.state.timeSelectionRadioBox,
            drp_default_time: this.state.drp_default_time,
        };
        // console.log('values')
        // console.log('values')
        // console.log(values)
        // console.log(Object.keys(values).length)
        if ((objRestaurantProvince === undefined || (!objRestaurantProvince)) || (values === null || Object.keys(values).length === 0)) {
        // if ((objtax === undefined || (!objtax)) || (values === null || Object.keys(values).length === 0)) {
                return (
                <LoadingSpinner />
                )
        }else{
            // console.log('values.delivery_type')
            // console.log('values.delivery_type')
            // console.log('values.delivery_type')
            console.log('values.appartment_unit_number in order confirm page')
            console.log('values.appartment_unit_number in order confirm page')
            console.log('values.appartment_unit_number in order confirm page')
            console.log(values.appartment_unit_number)
            return (
                    <Fragment>
                        {/* <Script
                            url="https://checkout.stripe.com/checkout.js"
                            onLoad={this.handleStripeScriptLoad}
                        /> */}
                        <header className="container inner-header">
                            <div className="row">
                                <div className="col-12 mb20">
                                    <img src={dropLeftPng} alt="icons" className="mw-100 cursor-pointer" onClick={this.props.history.goBack} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {/* <h4>Ticket Number {this.state.maxTicketNumber}</h4> */}
                                    <h4>Confirm your order.</h4>
                                </div>
                            </div>
                        </header>
                        <div className="inner-body inner-body-custom myCartPage OrderReviewPage">
                            <div className="container my-2">
                                <div className="row bg-grey product-bill">
                                    <div className="col-8 mb-3">
                                        <h4>{formatOrderDate(new Date())}</h4>
                                    </div>
                                    <div className="col-4">
                                        <h4 className="pricing-tag text-danger text-right"><span>${calculateFinalTotalOfOrder(values,this.state.selectedDeliveryPaymentMethod,this.state.card_processing_fee)}</span></h4>
                                    </div>
                                    <div className="col-12 billing-address">
                                        <p>{countOrderItems(values.cart)} Items order</p>
                                        {/* <p>{parseInt(1)+parseInt(values.cart.length)} Items order</p> */}
                                        {/* <p>{parseInt(1)+parseInt(values.cart[0].selectedComboItems.length)} Items order</p> */}
                                        <p>
                                            {values.address}
                                        </p>
                                        {
                                            values.delivery_type === 'delivery' && values.appartment_unit_number !== '' ?
                                            <p>{values.appartment_unit_number}</p>
                                            : null
                                        }
                                        <p>{values.phone_no}</p>
                                        <p>{values.notes}</p>
                                    </div>
                                </div>


                                <OrderReviewCartItemBlock
                                    products={cart}
                                    type="cart"
                                />
                                {
                                    cart.length > 0 && values.delivery_type === 'pickup' ?
                                    <Fragment>
                                        <PickupForm
                                            initialValues={initialValues}
                                            handelOnChangeTimeKeeper={this.handelOnChangeTimeKeeper} 
                                            handleOnChangetimeSelectionRadioBox={this.handleOnChangetimeSelectionRadioBox}
                                            onSubmitPickupForm={this.onSubmitPickupForm} 
                                            handleOnChangeDrfDefaultTime={this.handleOnChangeDrfDefaultTime} 
                                            pickupForm={this.pickupForm}
                                            time_picker_time={this.state.time_picker_time}
                                        />
                                        
                                    </Fragment>
                                    : null
                                }
                                {
                                    cart.length > 0 ?
                                        <Fragment>
                                            {
                                                values.delivery_type === 'delivery' && this.state.card_processing_fee !== false ?
                                                <div className="row bg-grey product-bill">
                                                    <div className="col-12">
                                                        <p className="text-center txtPayMethod">
                                                            How will you pay the delivery driver when they arrive?
                                                        </p>
                                                        
                                                        <div className="row">
                                                            <div className="col">
                                                                <button type="button" onClick={()=>this.btnOnClickPaymentMethodType('cash')} className={`btn btn-light btn-lg btn-block bg-white ${this.state.selectedDeliveryPaymentMethod ===  'cash' ? 'btnSelectedPaymentMethod' : ''} `}><i className="far fa-money-bill-alt"></i> Cash </button>
                                                            </div>
                                                            <div className="col">
                                                                <button type="button" onClick={()=>this.btnOnClickPaymentMethodType('credit')} className={`btn btn-light btn-lg btn-block bg-white ${this.state.selectedDeliveryPaymentMethod ===  'credit' ? 'btnSelectedPaymentMethod' : ''} `}><i className="fa fa-credit-card"></i> Debit/Credit </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               : null
                                            }
                                            <div className="row text-right row-totals mb10">
                                                <div className="col-12">
                                                    <p>Sub total : ${parseFloat(values.subTotal).toFixed(2)} </p>
                                                </div>
                                                <div className="col-12">
                                                    <p>Tax : ${parseFloat(values.taxAmount).toFixed(2)} </p>
                                                </div>
                                                <div className="col-12">
                                                    <p>Total : ${parseFloat(values.total).toFixed(2)} </p>
                                                </div>
                                                {
                                                    values.delivery_type === 'delivery' ?
                                                    <div className="col-12 mt10">
                                                        <p>Delivery fee : ${parseFloat(values.delivery_amount).toFixed(2)} </p>
                                                    </div>
                                                    : null
                                                }
                                                {
                                                    values.delivery_type === 'delivery' && this.state.selectedDeliveryPaymentMethod === 'credit' ?
                                                    <div className="col-12">
                                                        <p>Card processing fee : ${parseFloat(this.state.card_processing_fee).toFixed(2)} </p>
                                                    </div>
                                                    : null
                                                }
                                                {
                                                    values.delivery_type === 'delivery'?
                                                    <div className="col-12">
                                                        <p>Total including fee : ${calculateFinalTotalOfOrder(values,this.state.selectedDeliveryPaymentMethod,this.state.card_processing_fee)} </p>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                            <div className="row product-action-btn">
                                                <div className="col">
                                                    <div className="my-cart-btn">
                                                        {/* <button ref={this.btnCash} onClick={()=>this.onSubmitForm('cash')} className="btn btn-primary w-100" type="button"  > */}
                                                        <button  ref={this.btnContinue} onClick={()=>this.btnOnConfirmClick()} className="btn btn-primary w-100" type="button"  >
                                                            Continue
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* <SubmitOrderButtons 
                                                    btnCredit={this.btnCredit}
                                                    btnCash={this.btnCash}
                                                    loading_stripe_keys={this.state.loading_stripe_keys}
                                                    is_stripe_keys_setup={this.state.is_stripe_keys_setup}
                                                    isOrderSavingLoader={this.state.isOrderSavingLoader}
                                                    onSubmitForm={this.onSubmitForm}
                                                /> */}
                                                {/* {
                                                    this.state.loading_stripe_keys === false ?
                                                            <Fragment>
                                                            {
                                                                this.state.isOrderSavingLoader === false ?
                                                                <Fragment>
                                                                {
                                                                    this.state.is_stripe_keys_setup  ?
                                                                    <Fragment>
                                                                        <div className="col">
                                                                            <div className="my-cart-btn">
                                                                                <button ref={this.btnCredit} onClick={()=>this.onSubmitForm('credit')} className="btn btn-primary w-100" type="button"  >
                                                                                    Credit/Debit
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="my-cart-btn">
                                                                                <button ref={this.btnCash} onClick={()=>this.onSubmitForm('cash')} className="btn btn-primary w-100" type="button"  >
                                                                                    Cash
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                    : 
                                                                    <Fragment>
                                                                        <div className="col">
                                                                            <div className="my-cart-btn">
                                                                                <button ref={this.btnCash} onClick={()=>this.onSubmitForm('cash')} className="btn btn-primary w-100" type="button"  >
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </Fragment>
                                                                }
                                                            </Fragment>
                                                                : <LoadingSpinner subClass={'mt10 mb10'} />
                                                            }
                                                        </Fragment>
                                                    : <LoadingSpinner subClass={'mt10 mb10'} />
                                                } */}
                                                
                                            </div>
                                        </Fragment>
                                    :null
                                }
                                

                            </div>
                        </div>
                    
                        
                    </Fragment>
                    
            
            )
        }
    }
}
function mapStateToProps(state) {
    // console.log('state in ingr')
    // console.log(state)
    return { 
        cart : state.cart,
        objtax : state.settings.objtax,
        objResturantDeliveryAvailability : state.settings.objResturantDeliveryAvailability,
        objRestaurantDeliveryFees : state.settings.objRestaurantDeliveryFees,
        order : state.order,
        objRestaurantPhoneNumber : state.settings.objRestaurantPhoneNumber,
        objRestaurantStripeApiKeys : state.settings.objRestaurantStripeApiKeys,
        objRestaurantProvince : state.settings.objRestaurantProvince,
        objRestaurantCardProcessingFee: state.settings.objRestaurantCardProcessingFee,
    };
}

// export default Cart
export default connect(mapStateToProps, { fetchMaxTicketNumberOfOrder, fetchRestaurantCardProcessingFee, fetchResturantDeliveryFees,fetchResturantPhoneNumber,fetchResturantTax, fetchRestaurantProvince, fetchResturantDeliveryAvailability,removeCartItem,removeCartSubItem,submitCreateOrder,clearCart,fetchResturantStripeApiKeys })(withRouter(OrderReview));
